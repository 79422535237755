@use "../utilities/settings" as *;

.btn {
	appearance: none;
	background: none;
	border-radius: 30px;
	color: white;
	cursor: pointer;
	font-family: $button-font;
	font-size: 18px;
	height: 55px;
	line-height: 55px;
	max-width: 100%;
	padding: 0 25px;
	text-align: center;
	text-decoration: none;
	transition: all .2s linear;
	white-space: nowrap;
	letter-spacing: 0.03em;
	// overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	display: block;
	

	@media(min-width: $breakpoint-lg) {
		padding: 0px 35px;

		&.btn-hover:hover {
			padding: 0px 50px;
		}
	}
	
	&:hover {
		color: white;
		text-decoration: none;
		opacity: 1;
		
		a {
			text-decoration: none;
			opacity: 1;
		}
	}
	
	&:disabled,
	&:disabled:hover {
		background: rgba($grijs, .5) !important;
		color: white !important;
		cursor: not-allowed;
	}
	
	&.btn-primary {
		background: $groen;
		
		&.btn--opnieuw {
			display: none;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	
	&.btn-secondary {
		background: $paars;
		
		&.btn--bekijk-resultaat {
			display: none;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	
	.fas, .far {
		color: white;
		font-size: inherit;
		line-height: inherit;
		margin-right: 5px;
		position: relative;
	}
}

button {
	appearance: none;
	background: transparent;
	border: 0;
	cursor: pointer;
}

.buttons {
	padding: 25px 0;
	
	.btn {
		margin: 7.5px 0;
		width: 100%;
		
		@media (min-width: $breakpoint-md) {
			width: auto;
		}
	}
}
@use "../utilities/settings" as *;

.location {
	bottom: 0;
	display: none;
	font-size: 16px;
	left: 0;
	width: 100%;
	z-index: 0;
	margin-top: 50px;
	padding-bottom: 30px;
	
	&.location--fixed {
		padding: 20px;
		position: fixed;
	}
}

.logged-in {
	.location {
		display: block;
	}
}

.panel {
	align-items: center;
	background: white;
	display: none;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	left: 0;
	position: relative;
	top: 0;
	width: 100%;
	z-index: 1;
	
	.panel__inner {
		display: block;
		height: 100%;
		margin: 0 auto;
		max-width: 550px;
		padding: 50px 35px;
		width: 100%;
	}
	
	
	/*** Upload foto ***/
	&.panel--upload {
		display: block;
		
		.panel__inner {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		
		label[for=file] {
			//display: none;
			margin-top: 15px;
			position: relative;
			z-index: 2;
		}
		
		#file {
			opacity: 0;
			
			&::after {
				content: '';
				cursor: pointer;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 1;
			}
		}
		
		.upload-circle {
			animation: pulse-upload-circle 1s infinite alternate;
			margin: 80px 0 40px;
			position: relative;
			
			
			.loader {
				left: 50%;
				margin: -50px 0 0 -50px;
				position: absolute;
				top: 50%;
				z-index: 1;
			}
			
			.upload-circle__icon {
				align-items: center;
				background: rgba($paars, .8);
				border: 15px solid $paars;
				border-radius: 100%;
				box-shadow: 0 2px 74px 0 $paars;
				display: flex;
				height: 300px;
				justify-content: center;
				margin: 0 auto;
				width: 300px;
				
				img {
					width: 50%;
				}
			}
		}
	}
	
	/*** Bewerk tekst ***/
	&.panel--content {
		display: none;
		justify-content: flex-start;
		
		
		#uploaded-img {
			max-height: 500px;
			object-fit: cover;
			width: 100%;
		}
	}
	
	// close btn
	&.panel--content,
	&.panel--result {
		
		.close {
			background: $groen;
			z-index: 1;
			
			i {
				color: white;
			}
		}
	}
	
}


/*= Pagination loader */

@-webkit-keyframes pulse {
	from {
		opacity: 1;
		transform: scale(1.4);
	}
	
	to {
		opacity: 0.5;
		transform: scale(0.6);
	}
}

@keyframes pulse {
	from {
		opacity: 1;
		transform: scale(1.3);
	}
	
	to {
		opacity: 0.5;
		transform: scale(.6);
	}
}

@-webkit-keyframes pulse-upload-circle {
	from {
		transform: scale(1.1);
	}
	
	to {
		transform: scale(1);
	}
}

@keyframes pulse-upload-circle {
	from {
		transform: scale(1.1);
	}
	
	to {
		transform: scale(1);
	}
}

.loader {
	align-items: center;
	background: rgba($grijs, .3);
	bottom: 0;
	display: none;
	height: 100%;
	justify-content: center;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 999;
	
	&.loader--app {
		background: #fff;
		display: flex;
	}
	
	.pulsing-dot {
		animation: pulse .5s infinite alternate;
		background: $groen;
		border-radius: 50%;
		height: 40px;
		margin: 1.5em auto;
		transform-origin: center center;
		width: 40px;
	}
}

.uploaded-img {
	border-radius: 15px;
	box-shadow: 0px 2px 75px 0px rgba(0, 0, 0, 0.21);
	height: 0;
	margin-bottom: 20px;
	max-width: 520px;
	padding-top: 75%;
	position: relative;
	width: 100%;
	
	&::after {
		background-image: linear-gradient(180deg, black 0%, transparent 100%);
		border-radius: 15px;
		content: "";
		height: 100%;
		left: 0;
		opacity: .2;
		position: absolute;
		top: 0;
		transition: all 200ms ease;
		width: 100%;
	}
	
	.circle-icon-wrapper {
		align-items: center;
		color: #fff;
		display: flex;
		left: -30px;
		position: absolute;
		top: 30px;
		z-index: 2;
		
		.circle-icon {
			align-items: center;
			background-color: #fff;
			border-radius: 100%;
			color: #FFF;
			display: flex;
			height: 60px;
			justify-content: center;
			margin-right: 15px;
			width: 60px;
			
			img {
				width: 30px;
			}
		}
		
		.label-aanpassen {
			color: white;
			cursor: pointer;
			font-weight: bold;
			text-shadow: 0 0 2px black;
			
			&::after {
				content: '';
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
		
		input {
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
	
	.uploaded-img__img {
		border-radius: 15px;
		height: 100%;
		left: 50%;
		object-fit: cover;
		overflow: hidden;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: all 200ms ease;
		width: 100%;
	}
	
	&.loading .post-figure {
		filter: blur(4px);
	}
	
	&::after {
		opacity: .3;
	}
}

.content-progress {
	background: $lichtgrijs;
	border-radius: 2px;
	display: block;
	height: 4px;
	margin: 20px 0 0 0;
	overflow: hidden;
	position: relative;
	width: 100%;
	
	.content-progress__bar {
		background: $paars;
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		transform: translateX(-100%);
		width: 100%;
	}
}

.aantal-tekens {
	display: block;
	font-size: smaller;
	margin-top: 3px;
	text-align: right;
	width: 100%;
}

.bedankt {
	display: none;
	font-weight: bold;
	margin-top: 35px;
	text-align: center;
}
@use "../utilities/settings" as *;

section#login {
	left: 50%;
	top: 50%;
	height: calc(100% - 40px);
	position: absolute;
	transform: translate(-50%, -50%);
	width: calc(100vw - 40px);
	z-index: 2;
	box-shadow:
		20px 0 0 0 white,
		0 20px 0 0 white,
		0 0 20px 0 white,
		0 0 0 20px white;
	
	@media (min-width: $breakpoint-xs) {
		height: calc(100vh - 80px);
		box-shadow:
			40px 0 0 0 white,
			0 40px 0 0 white,
			0 0 40px 0 white,
			0 0 0 40px white;
	}
	
	.close {
		right: 10px;
		top: 10px;
	}
	
	input {
		text-align: center;
	}
}

.login {
	background: darken($lichtgroen, 15%);
	border-bottom-right-radius: 100px;
	height: 100%;
	padding: 60px 30px;
	width: 100%;
	
	h1, p {
		color: white;
	}
	
	@media (min-width: $breakpoint-lg) {
		padding: 120px 60px;
	}
}

.close {
	background: #fff;
	border-radius: 50%;
	height: 40px;
	position: absolute;
	right: 30px;
	top: 30px;
	width: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	display: none;
	
	i {
		color: $groen;
		font-size: 25px;
	}
	
	@media (min-width: $breakpoint-md) {
		width: 60px;
		height: 60px;
		
		i {
			font-size: 40px;
		}
	}
}

.logged-in {
	.close {
		display: flex;
	}
}
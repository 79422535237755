@use "../utilities/settings" as *;

#form-elke-dag-iets-leuks {
	width: 100%;
	height: 100%;
}

.form-row {
	color: $paars;
	display: inline-flex;
	flex-wrap: wrap;
	margin-top: 25px;
	position: relative;
	width: 100%;
	
	&.w50 {
		width: 100%;
		
		input {
			margin: 0;
		}
		
	}
	
	label {
		color: inherit;
		font-weight: 700;
		line-height: 1.3;
		margin-bottom: 5px;
		width: 100%;
	}
	
	input, textarea, select {
		appearance: none;
		border: 2px solid darken($lichtgrijs, 15%);
		border-radius: 4px;
		font-size: 16px;
		margin: 0;
		padding: 0 15px;
		position: static;
		width: 100%;
		
		&:focus {
			border: 2px solid $paars;
			outline: $paars;
		}
		
		&::placeholder {
			color: #cecece;
		}
	}
	
	input, select {
		height: 66px;
		line-height: 66px;
	}
	
	textarea {
		line-height: 1.3;
		padding: 15px;
		resize: none;
	}
	
	select {
		appearance: auto;
	}
}

hr {
	border: 0;
	border-top: 2px solid #EEF2F8;
	height: 0;
	margin: 40px 0 10px;
}

.btn {
	margin-top: 15px;
}

input {
	&[type=submit],
	&[type=button] {
		appearance: none;
		border: 0 !important;
		margin-top: 20px;
	}
}

@use "settings" as *;

html,body {
	overflow-x: hidden;
	height: 100%;
	width: 100%;
}

//:root {
//	--app-height: 100%;
//}
//
//html,
//body {
//	padding: 0;
//	margin: 0;
//	width: 100vw;
//	height: 100vh;
//
//	@media not all and (hover:hover) {
//		height: var(--app-height);
//	}
//}


.flexbox {
	display: flex;
	justify-content: center;
	
	&-middle, &-center {
		align-items: center;
		justify-content: center;
	}
	
	&-start {
		justify-content: flex-start;
	}
	
	&-start-center {
		align-items: center;
		justify-content: flex-start;
	}
	
	&-column {
		flex-direction: column;
	}
	
	&-wrap {
		flex-wrap: wrap;
	}
}

.row-reverse {
	flex-direction: row-reverse;
}

.hide-sm {
	@media(max-width: $breakpoint-sm) {
		display: none !important;
	}
}

.show-sm {
	@media (min-width: $breakpoint-md) {
		display: none !important;
	}
}

.hide-md {
	@media(min-width: $breakpoint-md) {
		display: none !important;
	}
}

.show-md {
	@media (max-width: $breakpoint-md) {
		display: none !important;
	}
}

.hide-lg {
	@media(min-width: $breakpoint-lg) {
		display: none !important;
	}
}

.show-lg {
	@media (max-width: $breakpoint-lg) {
		display: none !important;
	}
}

.section {
	padding: 25px 10px;
	position: relative;
	
	@media(min-width: $breakpoint-lg) {
		padding: 50px 0px;
	}
	
	&.large-padding {
		padding-bottom: 200px;
	}
	
	&.breadcrumbs {
		padding: 20px 0 0;
		
		@media(min-width: $breakpoint-lg) {
			padding-bottom: 0;
		}
	}
	
	&.section--title {
		padding-bottom: 0;
	}
	
	&.section--filter {
		padding-top: 25px;
	}

	&.no-padding--top {
		padding-top: 0;
	}
	
	ul {
		margin-bottom: 15px;
	}
}

.no-gutter {
	> div {
		&:nth-of-type(odd) {
			@media (max-width: $breakpoint-sm) {
				padding-right: 5px;
			}
		}
		
		&:nth-of-type(even) {
			@media (max-width: $breakpoint-sm) {
				padding-left: 5px;
			}
		}
	}
	
	.slick-slide {
		@media (max-width: $breakpoint-sm) {
			padding: 0 5px;
		}
	}
}

.mb30,
p.mb30 {
	margin-bottom: 30px;
}

.mb50,
p.mb50 {
	margin-bottom: 50px;
}

.kolom {
	
	&:first-child {
		margin-bottom: 30px;
		
		@media(min-width: $breakpoint-md) {
			margin-bottom: 0;
		}
	}
	
	p {
		margin-bottom: 30px;
	}
	
	.btn {
		margin-top: auto;
	}
}

.noscroll {
	overflow-y: hidden;
}

.screen-reader-text {
	display: none;
}

.page-mijn-kind-inschrijven, .page-rondleiding-aanvragen {
	background: #EEF2F8;
}

.page-privacystatement .section {
	ul {
		padding-left: 25px;
		margin-bottom: 15px;
		
		li {
			line-height: 1.5;
			list-style: disc;
		}
	}
}
iframe {
	width: 100%;
	border: 0;
	appearance: none;
}
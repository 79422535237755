// kleuren
// primaire kleuren
$paars: #42325E;
$groen: #4E643C;
$zwart: #232323;
$grijs: #969696;
$rood: #9E2732;
$oranje: #CD5C2F;
$roze: #CCAAB2;

// lichte kleuren
$lichtgroen: #C8DCD1;
$lichtpaars: #CCAAB2;
$lichtbruin: #E9E1D6;
$lichtgrijs: #F4F4F4;
$lichtgeel: #F9EEBE;

// fonts
@font-face {
    font-family: 'vag_rundschrift_dregular';
    src: url('../fonts/vagrundschriftd-regular-webfont.ttf') format('truetype'),
		 url('../fonts/vagrundschriftd-regular-webfont.woff2') format('woff2'),
         url('../fonts/vagrundschriftd-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'koara_bold';
    src: url('../fonts/koara_bold.woff2') format('woff2'),
         url('../fonts/koara_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$button-font: "vag_rundschrift_dregular", "Roboto", sans-serif;
$heading-font: "koara_bold", "Roboto", sans-serif;

// breakpoints
$breakpoint-xs: 452px;
$breakpoint-sm: 540px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1500px;

